export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig();

  if (process.server) {
    try {
      const token = await $fetch<string | null>('/api/token');

      await $fetch(`/social/rooms/${to.params.id}`, {
        baseURL: config.privateTiltApi,
        headers: {
          Authorization: `Bearer ${token}`,
          'Tilt-Cloudformation-Secret': config.cloudformationSecret,
        },
      });
    } catch(e) {
      console.log('This room does not exist');
      return navigateTo(config.baseUrl, {
        redirectCode: 301,
        replace: true,
        external: true,
      });
    }
  }
});
